<script>
export default {

}
</script>

<template>
  <div>
    <card>
      <!-- RouterLink -->
    </card>
    <router-view>
    </router-view>
  </div>
</template>